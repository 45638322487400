import { Box, Button, Grid, LinearProgress, Tab, Tabs, TextField, Typography } from "@mui/material";
import { ControlHeaderLogo } from "../../Controls/ControlHeaderLogo/ControlHeaderLogo";
import { FormUserCampusPicker } from "../../Forms/User/FormUserCampusPicker/FormUserCampusPicker";
import { useContextUniversidad } from "../../../contexts/ContextUniversidad/ContextUniversidad";
import { useEffect, useState } from "react";
import { useIsLoading } from "../../../hooks/Shared/useIsLoading/useIsLoading";
import { useContextApiService } from "../../../contexts/ContextApiService/ContextApiService";
import { IRecordCarreraConfig } from "../../../interfaces/Records/IRecordCarreraConfig";
import { formatDuracion } from "../../../utils/MonetFormater/MoneyFormater";
import { ControlRenderResults } from "../../Controls/ControlRenderResults/ControlRenderResults";
import { ControlRenderResultPregunta } from "../../Controls/ControlRenderResultPregunta/ControlRenderResultPregunta";
import { ControlRenderResultPromoBeca } from "../../Controls/ControlRenderResultPromoBeca/ControlRenderResultPromoBeca";
import { ControlWhatsAppButton } from "../../Controls/ControlWhatsAppButton/ControlWhatsAppButton";



export const SectionResultado = () => {

    const {
        AppUser,
        Universidad,
        LineaNegocio,
        ConfigCarreraTemp,
        ConfigStyle,
        Modalidad,
        ConfigCarrera,
        Campus,
        CtxRecord,
        PromoPrecio,
        PromoBanners,
        PromoBecas,
        UpdateContextProp
    } = useContextUniversidad();

    const { ApiService } = useContextApiService();
    const [records, setRecords] = useState<IRecordCarreraConfig[]>([]);
    const [selectedRecord, setSelectedRecord] = useState<IRecordCarreraConfig | undefined>(undefined);
    const [userPromedio, setUserPromedio] = useState<number>(0);
    const { isLoading, setIsLoading } = useIsLoading(true);

    const getDatos = async () => {

        setIsLoading(true);

        console.info("ctx", CtxRecord);

        const response = await ApiService.Admin.CarreraConfiguracion.GetByRecintoModalidadCarrera(
            Campus.chainId,
            Modalidad.chainId,
            ConfigCarreraTemp.chainId
        );

        console.info("response seleccion", response, AppUser);
        for (let index = 0; index < response.registros.length; index++) {
            const consulta = response.registros[index];

            const cotResponse = await ApiService.Admin.Cotizacion.Add({
                UserId: AppUser.chainId,
                IdConfiguracionCarrera: consulta.chainId
            });

            console.info("cotizacion", cotResponse);

        }

        setSelectedRecord(response.registros[0]);
        setRecords(response.registros);

    }

    const getPromos = () => {

        const promosValidas = PromoPrecio.filter((promo) => {
            return promo.tipoPrecioId.toUpperCase() === "9342B923-BFA5-4093-8184-1A7FC6E8569B"
                && promo.recintoId.toUpperCase() === selectedRecord?.recintoId.toUpperCase()
                && promo.lineaNegocioId.toUpperCase() === selectedRecord?.lineaNegocioId.toUpperCase();
        });
        console.info("Promos", promosValidas);
        return promosValidas.length > 0 ? [promosValidas[0]] : [];

    }

    useEffect(() => {

        if (records.length > 0) {

            setIsLoading(false);

        }

    }, [records]);

    useEffect(() => {

        if (Campus.chainId.length > 0) {

            getDatos();

        }

    }, [Campus]);


    return (
        <Grid id="seleccion-colegiatura" container sx={{ minHeight: '100vh', alignContent: "flex-start" }}>
            <Grid item xs={12} sx={{
                backgroundImage: `url('${ConfigStyle.urlFondoResultado}')`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                height: "20rem",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                position: "relative"
            }}>
                <Box sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    zIndex: 1
                }} />
                <Box sx={{
                    paddingLeft: "2rem",
                    zIndex: 3
                }}>
                    <Typography variant="h4" color="white">
                        {ConfigCarreraTemp.nombre}
                    </Typography>
                    <Typography variant="h6" color="white">
                        {Campus.nombre}
                    </Typography>
                </Box>
            </Grid>
            <Box sx={{
                width: "100%", padding: {
                    xs: "1rem",
                    md: "4rem"
                }
            }}>
                <Grid item xs={12} container spacing={3} sx={{ marginTop: "0.5rem" }}>
                    <Grid item xs={12} md={4}>
                        {
                            Universidad.showPrimerPago && selectedRecord !== undefined &&
                            <ControlRenderResultPregunta
                                title="Primer Pago"
                                pregunta={`¿Cuánto voy a pagar para ingresar en ${Universidad.nombre}?`}
                                cantidad={selectedRecord?.primerPago}
                                colorBg="#ffe7fe"
                                colorMain="#fa5353"
                            />
                        }
                        {
                            getPromos().map((promo) => {


                                if (promo.recintoId.toUpperCase() === Campus.chainId.toUpperCase() && promo.lineaNegocioId.toUpperCase() === LineaNegocio.chainId.toUpperCase()) {

                                    // console.info("Promo Recinto ID", promo.recintoId.toUpperCase());
                                    // console.info("Campus ID", Campus.chainId.toUpperCase());

                                    // console.info("Promo Linea Negocio ID", promo.lineaNegocioId.toUpperCase());
                                    // console.info("Linea Negocio ID", LineaNegocio.chainId.toUpperCase());

                                    const montoDescuento = (promo.porcentajeDescuento / 100) * (selectedRecord?.primerPago ?? 0);
                                    const nuevoPago = (selectedRecord?.primerPago ?? 0) - montoDescuento;

                                    return (
                                        <div style={{ marginTop: "1rem" }}>
                                            <ControlRenderResultPregunta
                                                title={promo.nombre}
                                                pregunta={promo.descripcion}
                                                cantidad={nuevoPago}
                                                colorBg="#ffe7fe"
                                                colorMain="#fa5353"
                                            />
                                        </div>
                                    )
                                }
                            })
                        }
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {
                            Universidad.showColegiatura && selectedRecord !== undefined &&
                            <ControlRenderResultPregunta
                                title="Mensualidad"
                                pregunta={`¿Cuánto voy a pagar de colegiatura en ${Universidad.nombre}?`}
                                cantidad={selectedRecord?.colegiatura}
                                colorBg="#d6e4ff"
                                colorMain="#538efa"
                            />
                        }
                        {
                            PromoPrecio.filter((promo) => promo.tipoPrecioId.toUpperCase() === "FF4B3B05-9D55-4B61-869F-600A43A59C5B").map((promo) => {

                                if (promo.recintoId === Campus.chainId && promo.lineaNegocioId === LineaNegocio.chainId) {

                                    const montoDescuento = (promo.porcentajeDescuento / 100) * (selectedRecord?.colegiatura ?? 0);
                                    const nuevoPago = (selectedRecord?.colegiatura ?? 0) - montoDescuento;

                                    return (
                                        <div style={{ marginTop: "1rem" }}>
                                            <ControlRenderResultPregunta
                                                title={promo.nombre}
                                                pregunta={promo.descripcion}
                                                cantidad={nuevoPago}
                                                colorBg="#d6e4ff"
                                                colorMain="#538efa"
                                            />
                                        </div>
                                    )

                                }
                            })
                        }
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {
                            Universidad.showPagoTotal && selectedRecord !== undefined &&
                            <ControlRenderResultPregunta
                                title="Pago Total"
                                pregunta={`¿Cuánto voy a pagar en total por estudiar la carrera en ${Universidad.nombre}?`}
                                cantidad={selectedRecord?.costoTotal}
                                colorBg="#e8ffea"
                                colorMain="#09a814"
                            />
                        }
                        {
                            PromoPrecio.filter((promo) => promo.tipoPrecioId.toUpperCase() === "E8E67DA8-9A80-44D2-A85C-A35A9718168F").map((promo) => {

                                if (promo.recintoId === Campus.chainId && promo.lineaNegocioId === LineaNegocio.chainId) {

                                    const montoDescuento = (promo.porcentajeDescuento / 100) * (selectedRecord?.costoTotal ?? 0);
                                    const nuevoPago = (selectedRecord?.costoTotal ?? 0) - montoDescuento;

                                    return (
                                        <div style={{ marginTop: "1rem" }}>
                                            < ControlRenderResultPregunta
                                                title={promo.nombre}
                                                pregunta={promo.descripcion}
                                                cantidad={nuevoPago}
                                                colorBg="#e8ffea"
                                                colorMain="#09a814"
                                            />
                                        </div>
                                    )
                                }
                            })
                        }
                    </Grid>
                    {
                        selectedRecord !== undefined &&
                        <Grid item xs={12}>
                            <ControlRenderResultPromoBeca
                                record={selectedRecord}
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">
                            * Los precios expresados en esta herramienta son referenciales y pueden variar. Consulte
                            términos y condiciones directamente con un asesor educativo. Aplican restricciones
                        </Typography>
                    </Grid>
                    {
                        selectedRecord !== undefined &&
                        <Grid item xs={12} container spacing={3}>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                <ControlWhatsAppButton
                                    record={selectedRecord}
                                />
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Box>
            {
                PromoBanners.length > 0 &&
                <Grid item xs={12} container spacing={3}>
                    {
                        PromoBanners.map((banner) => {
                            return (
                                <Grid item xs={12}>
                                    <Box sx={{ marginTop: "1rem" }}>
                                        <img
                                            src={banner.urlImagen}
                                            alt={banner.nombre}
                                            style={{
                                                width: "100%",
                                                maxHeight: "22rem",
                                                objectFit: "fill"
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            }
        </Grid>
    );

}